#containerNoPage{
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
}

#bugai{
    font-size: 60px;
    margin-top: 400px;
    margin-left: 10vw;
}