#containerHeader{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
}

#containerHeader a{
    text-decoration: none;
}

#logoTxt{
    font-size: 40px;
    font-weight: 800;
    color: #4c8bf5;
}

.section{
    margin-left: 5vw;
}

.navTxt{
    font-size: 24px;
    font-weight: 400;
    color: black;
    margin-top: 52px;
    transition: all .1s ease-in-out;
}

.navTxt:hover{
    transform: scale(1.1);
}