section{
    margin-top: 5vh;
    width: 40vw;
    margin-left: 30vw;
    min-width: 600px;
}

#rubric{
    font-size: 6rem;
    font-weight: 500;
    text-align: center;
}

.subRubric{
    font-size: 56px;
    font-weight: 400;
    margin-top: 100px;
}

.whyList{
    margin-left: 2vw;
    font-size: 28px;
    font-weight: 300;
}
